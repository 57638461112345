// Generated by Framer (26d4882)

import { addFonts, cx, CycleVariantState, getFonts, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";
import Clipboard from "https://framerusercontent.com/modules/Hj20QU19p80mpYsvesiZ/RfHh9MIwqlgi04HKZ3Qo/Clipboard.js";
const ClipboardFonts = getFonts(Clipboard);

const enabledGestures = {kfBJmS99r: {hover: true}};

const cycleOrder = ["kfBJmS99r"];

const serializationHash = "framer-CjwYv"

const variantClassNames = {kfBJmS99r: "framer-v-25224u"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transitions = {default: {damping: 60, delay: 0, mass: 1, stiffness: 500, type: "spring"}};

const transformTemplate = (_, t) => `translate(-50%, -50%) ${t}`

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const getProps = ({height, id, width, ...props}) => { return {...props} }

const createLayoutDependency = (props, variants) => variants.join('-') + props.layoutDependency

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale } = useLocaleInfo()

const {style, className, layoutId, variant, ...restProps} = getProps(props)

const {baseVariant, classNames, gestureVariant, setGestureState, setVariant, transition, variants} = useVariantState({cycleOrder, defaultVariant: "kfBJmS99r", enabledGestures, transitions, variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const ref1 = React.useRef<HTMLElement>(null)

const defaultLayoutId = React.useId()

const sharedStyleClassNames = []

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<motion.div initial={variant} animate={variants} onHoverStart={() => setGestureState({isHovered: true})} onHoverEnd={() => setGestureState({isHovered: false})} onTapStart={() => setGestureState({isPressed: true})} onTap={() => setGestureState({isPressed: false})} onTapCancel={() => setGestureState({isPressed: false})} className={cx("framer-CjwYv", ...sharedStyleClassNames, classNames)} style={{display: "contents"}}>
<Transition value={transition}><motion.div {...restProps} className={cx("framer-25224u", className)} data-framer-name={"Variant 1"} layoutDependency={layoutDependency} layoutId={"kfBJmS99r"} ref={ref ?? ref1} style={{...style}} {...addPropertyOverrides({"kfBJmS99r-hover": {"data-framer-name": undefined}}, baseVariant, gestureVariant)}><motion.div className={"framer-qnxru2-container"} layoutDependency={layoutDependency} layoutId={"ChIHtAurf-container"} transformTemplate={transformTemplate}><Clipboard borderRadius={5} bottomLeftRadius={5} bottomRightRadius={5} color={"rgb(255, 255, 255)"} content={"customsportsclub.com"} fill={"var(--token-5d7fe40f-1ebe-4154-8046-ea2bee0670dc, rgb(26, 26, 26)) /* {\"name\":\"black\"} */"} font={{}} height={"100%"} id={"ChIHtAurf"} isMixedBorderRadius={false} label={"customsportsclub@gmail.com"} layoutId={"ChIHtAurf"} padding={10} paddingBottom={10} paddingLeft={10} paddingPerSide={false} paddingRight={10} paddingTop={10} topLeftRadius={5} topRightRadius={5} width={"100%"} {...addPropertyOverrides({"kfBJmS99r-hover": {color: "var(--token-acb7bd7b-06fa-4d78-ad9c-6349809783a9, rgb(254, 254, 254)) /* {\"name\":\"White smoke\"} */"}}, baseVariant, gestureVariant)}/></motion.div></motion.div></Transition>
</motion.div>
</LayoutGroup>)

});

const css = [".framer-CjwYv [data-border=\"true\"]::after { content: \"\"; border-width: var(--border-top-width, 0) var(--border-right-width, 0) var(--border-bottom-width, 0) var(--border-left-width, 0); border-color: var(--border-color, none); border-style: var(--border-style, none); width: 100%; height: 100%; position: absolute; box-sizing: border-box; left: 0; top: 0; border-radius: inherit; pointer-events: none; }", "@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-CjwYv .framer-1grkuu5 { display: block; }", ".framer-CjwYv .framer-25224u { cursor: pointer; height: 51px; overflow: hidden; position: relative; width: 250px; }", ".framer-CjwYv .framer-qnxru2-container { flex: none; height: auto; left: 50%; position: absolute; top: 49%; width: auto; }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 51
 * @framerIntrinsicWidth 250
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","fixed"]},"tIUxFSNEY":{"layout":["fixed","fixed"]}}}
 * @framerImmutableVariables true
 */
const FramerCr0QSjttt: React.ComponentType<Props> = withCSS(Component, css, "framer-CjwYv") as typeof Component;
export default FramerCr0QSjttt;

FramerCr0QSjttt.displayName = "Gmail card";

FramerCr0QSjttt.defaultProps = {height: 51, width: 250};

addFonts(FramerCr0QSjttt, [...ClipboardFonts])